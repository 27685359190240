import React from "react"; 
import { Link, useLocation, useHistory } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Grid, TextField } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalParkingIcon from "@mui/icons-material/LocalParking"
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@mui/icons-material/Map';
import { styled } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MuiLink from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
            {'Copyright © '}
            <MuiLink color="inherit" href="http://hkmeca.org">
                HKMECA
            </MuiLink>{' '}
            {new Date().getFullYear()}
            {' All Rights Reserved.'}
        </Typography>
    );
}

const iconStyle = {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'warning.main',
    mr: 1,
    '&:hover': {
        // bgcolor: 'warning.dark',
    },
};

const awardsStyle = {
    mx: 1,
    '&:hover': {
        // bgcolor: 'warning.dark',
    },
};

const LANGUAGES = [
    {
        code: 'en-US',
        name: 'English',
    },
    {
        code: 'zh-TW',
        name: '中文',
    },
];

function Footer() {
    const location = useLocation();

    return (
        <React.Fragment>
            <Box
                sx={{
                    // display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    // minHeight: '100vh',
                    // width: '100vw',
                    // position: "fixed", 
                    // bottom: 0 
                }}
            >
                <Box
                    component="footer"
                    sx={{
                        py: 3,
                        px: 2,
                        mt: 'auto',
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? '#c9bea8'
                                : theme.palette.background.default,
                    }}
                >
                    <Box sx={{ mx: {md: 4}, mt: 1, display: 'flex' }}>
                        <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                            更新日期：2024年12月
                        </Typography>
                    </Box>
                    <CssBaseline />
                    <Box sx={{ mx: {md: 4}}}>
                        {/* <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
                            <Grid item sx={{ display: 'flex' }}>
                                <Box component="a" href="#" sx={iconStyle}>
                                    <img
                                        width="36"
                                        src="/img/icon/icons-youtube-48.png"
                                        alt="Youtube"
                                    />
                                </Box>
                            </Grid>
                        </Stack> */}
                        <Copyright />
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default Footer;

const PREFIX = "footer";

const classes = {
    root: `${PREFIX}-root`,
    selected: `${PREFIX}-selected`,
};

const Root = styled(BottomNavigation)(({ theme }) => ({
    [`&.${classes.root}`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.default
                : "#7A5D26",
        position: "fixed",
        width: "100%",
        bottom: "0",
        height: "initial",
        [`& .MuiBottomNavigationAction-root`]: {
            width: "40%",
            minWidth: "auto",
        },
        [`& .MuiBottomNavigationAction-label`]: {
            fontSize: "0.875rem",
        },
        [`& .Mui-selected.${classes.selected}`]: {
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
        },
    },
}));


