import React, { useState, useEffect, forwardRef } from "react";
import {
    Container, Slide, Box, Divider, Typography, Grid, Paper,
    Card, CardActionArea, CardMedia, CardContent, CardHeader, Avatar,
    Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@mui/material/colors';
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import OrganizationChart from "@dabeng/react-orgchart";

// React Router
import { useNavigate } from "react-router-dom";

const breadcrumbData = ["主頁", "關於我們", "名譽總監、名譽顧問及名譽法律顧問"];

function ConsultantPage(props) {
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // change title
    useEffect(() => {
        document.title = '名譽總監、名譽顧問及名譽法律顧問';
    });

    return (
        <RootBox sx={{ minHeight: 'calc(100vh - 210px)' }}>
            <Root className={classes.root}>
                <Box sx={{ mt: { xs: 8.25, md: 12.5 } }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
                    <Box sx={{ my: 4 }}>
                        <Typography variant="h4">{"名譽總監、名譽顧問及名譽法律顧問"}</Typography>
                    </Box>
                    <Box>
                        <div>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Card
                                    variant="outlined"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', maxWidth: '100%' }}
                                >
                                    <CardMedia
                                        component="img"
                                        image="img/consultant/consultant-org-chart.png"
                                        alt="org-chart.png"
                                        sx={{
                                            maxHeight: { xs: 233, md: 1000 },
                                            maxWidth: { xs: '100vw' },
                                        }}
                                    />

                                </Card>
                            </Box>
                            <CustomDivider sx={{ my: 3 }} className={'consultant'} />
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                        名譽總監
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            鍾國輝教授
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/consultant/consultant-1.png"
                                                src="/img/consultant/consultant-1.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                                鍾教授是著名的學術、科研工作者和結構工程師，在結構工程和鋼結構方面具豐富的專業知識。鍾教授現為香港理工大學土木及環境工程學系教授，亦是香港建築金屬結構協會創始會長。於2000 至 2007 年，鍾教授在香港工程師學會結構分部委員會及英國結構工程師學會擔任過多項職務。於2013至2015年，他出任英國結構工程師學會監事會成員。鍾教授更當選為英國結構工程師學會 2017 / 18 及 2019/20 副會長。鍾教授于2015年10月接受中華人民共和國科學技術部任命，成為國家鋼結構工程技術研究中心香港分中心主任。
                                                <br /><br />
                                                鍾教授於1984年在謝菲爾德大學獲頒受一等榮譽學士學位及Mappin勳章。同年，鍾教授獲得香港青年基金會頒發的研究生獎學金，並赴倫敦帝國理工學院攻讀結構工程研究學位。1988年，獲得鋼結構工程博士學位。
                                                <br /><br />
                                                鍾教授在英國鋼結構工程協會擔任研究工程師，然後於1989年1月至1995年4月期間擔任高級研究工程師。鍾教授於1995年5月回港工作，在兩間國際知名的工程顧問公司參與了多項鋼結構和鋼混組合高層建築結構的設計及建造。在1996年9月加入了香港理工大學從事科研教育工作至今。
                                                <br /><br />
                                                鍾教授致力於多項跨學科工程技術研究，特別是現代鋼結構和鋼混組合建築結構。多年來，他參與香港、英國、 歐盟和中國的建築和鋼結構設計標準、規範的制定。他的專業工作及科研成果亦應用在香港和中國的大型鋼結構建築專案。他發表了約200篇會議與期刊論文，以及9冊現代鋼結構技術專業設計指南。鍾教授多次獲邀作多個國際會議和專題討論會的主題講者，以及為專業課程中擔任專業導師。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/consultant/consultant-1.png"
                                                src="/img/consultant/consultant-1.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    鍾教授是著名的學術、科研工作者和結構工程師，在結構工程和鋼結構方面具豐富的專業知識。鍾教授現為香港理工大學土木及環境工程學系教授，亦是香港建築金屬結構協會創始會長。於2000 至 2007 年，鍾教授在香港工程師學會結構分部委員會及英國結構工程師學會擔任過多項職務。於2013至2015 年，他出任英國結構工程師學會監事會成員。鍾教授更當選為英國結構工程師學會 2017 / 18 及 2019/20 副會長。鍾教授於2015年10月接受中華人民共和國科學技術部任命，成為國家鋼結構工程技術研究中心香港分中心主任。
                                                </Typography>
                                                <br />
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    鍾教授於1984年在謝菲爾德大學獲頒受一等榮譽學士學位及Mappin勳章。同年，鍾教授獲得香港青年基金會頒發的研究生獎學金，並赴倫敦帝國理工學院攻讀結構工程研究學位。1988年，獲得鋼結構工程博士學位。
                                                </Typography>
                                                <br />
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    鍾教授在英國鋼結構工程協會擔任研究工程師，然後於1989年1月至1995年4月期間擔任高級研究工程師。鍾教授於1995年5月回港工作，在兩間國際知名的工程顧問公司參與了多項鋼結構和鋼混組合高層建築結構的設計及建造。在1996年9月加入了香港理工大學從事科研教育工作至今。
                                                </Typography>
                                                <br />
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                鍾教授致力於多項跨學科工程技術研究，特別是現代鋼結構和鋼混組合建築結構。多年來，他參與香港、英國、 歐盟和中國的建築和鋼結構設計標準、規範的制定。他的專業工作及科研成果亦應用在香港和中國的大型鋼結構建築專案。他發表了約200篇會議與期刊論文，以及9冊現代鋼結構技術專業設計指南。鍾教授多次獲邀作多個國際會議和專題討論會的主題講者，以及為專業課程中擔任專業導師。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            名譽顧問
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            董优博士
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/consultant/consultant-2.png"
                                                src="/img/consultant/consultant-2.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                                董博士的主要研究方向是在老化、災害和氣候變化的影響下，利用新型材料、結構系統和機器學習，對資產進行風險、彈性和可持續性的設計、評估和生命週期智慧維護，以幫助開發下一代低碳工程系統。此外，董博士的研究小組還專注於資料驅動的計算模型、物理引導的深度學習和數字孿生的智慧維護。最近的應用強調民用基礎設施、能源系統（如風力渦輪機）、網路（如交通、網路物理系統）和相互依存的基礎設施系統。其最終目標是説明開發下一代工程系統。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/consultant/consultant-2.png"
                                                src="/img/consultant/consultant-2.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    董博士的主要研究方向是在老化、災害和氣候變化的影響下，利用新型材料、結構系統和機器學習，對資產進行風險、彈性和可持續性的設計、評估和生命週期智慧維護，以幫助開發下一代低碳工程系統。此外，董博士的研究小組還專注於資料驅動的計算模型、物理引導的深度學習和數字孿生的智慧維護。最近的應用強調民用基礎設施、能源系統（如風力渦輪機）、網路（如交通、網路物理系統）和相互依存的基礎設施系統。其最終目標是説明開發下一代工程系統。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            名譽顧問
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            何浩祥博士
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/consultant/consultant-3.png"
                                                src="/img/consultant/consultant-3.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                                何博士是中國國家鋼結構工程研究中心（香港分中心）常務副秘書長、力學與鋼鐵材料實驗室負責人及香港理工大學土木與環境工程系的助理教授（研究）。
                                                <br /><br />
                                                何博士於 2000 年獲香港理工大學頒授土木和結構工程學士學位，2005年獲土木及結構工程博士學位。何博士的主要研究專案包括高性能鋼材、結構防火工程、計算流體動力學和非線性結構分析。他的專業領域包括鋼結構、冷彎鋼結構、金屬結構及竹結構、結構工程設計和結構防火設計。
                                                <br /><br />
                                                自2012年起，何博士開展了專門的理論模型和數值模型類比技術研究，應用於火災下建築結構和隧道結構的結構性能。此外，他提出多個用於火災受損結構的維修和補強方案，其實用性技術已在多項結構防火工程中得到應用。近年，何博士也開拓了新的研究方向，包括：在單調和迴圈作用下鋼及建築金屬的全變形範圍本構模型、斷裂力學和人工智慧輔助的結構工程設計。
                                                <br /><br />
                                                何博士亦積極參與了多個建築業專業團體的學術交流活動，包括香港建築金屬結構協會、冷彎金屬結構協會、以及中國鋼結構協會冷彎型鋼分會建築結構分部。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/consultant/consultant-3.png"
                                                src="/img/consultant/consultant-3.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    何博士是中國國家鋼結構工程研究中心（香港分中心）常務副秘書長、力學與鋼鐵材料實驗室負責人及香港理工大學土木與環境工程系的助理教授（研究）。
                                                </Typography>
                                                <br /><br />
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    何博士於 2000 年獲香港理工大學頒授土木和結構工程學士學位，2005年獲土木及結構工程博士學位。何博士的主要研究專案包括高性能鋼材、結構防火工程、計算流體動力學和非線性結構分析。他的專業領域包括鋼結構、冷彎鋼結構、金屬結構及竹結構、結構工程設計和結構防火設計。
                                                </Typography>
                                                <br /><br />
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    自2012年起，何博士開展了專門的理論模型和數值模型類比技術研究，應用於火災下建築結構和隧道結構的結構性能。此外，他提出多個用於火災受損結構的維修和補強方案，其實用性技術已在多項結構防火工程中得到應用。近年，何博士也開拓了新的研究方向，包括：在單調和迴圈作用下鋼及建築金屬的全變形範圍本構模型、斷裂力學和人工智慧輔助的結構工程設計。
                                                </Typography>
                                                <br /><br />
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    何博士亦積極參與了多個建築業專業團體的學術交流活動，包括香港建築金屬結構協會、冷彎金屬結構協會、以及中國鋼結構協會冷彎型鋼分會建築結構分部。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            名譽顧問
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            冼超舜博士
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/consultant/consultant-4.jpg"
                                                src="/img/consultant/consultant-4.jpg"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                                冼博士前麻省理工學院香港創新中心執行董事，在資訊技術和電信行業擁有25年經驗的資深人士。冼超舜在加拿大滑鐵盧大學獲得管理科學博士學位。目前，他是香港數碼港管理公司的董事會成員，香港理工大學商學院實踐教授及香港董事學會資深會員。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/consultant/consultant-4.jpg"
                                                src="/img/consultant/consultant-4.jpg"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    冼博士前麻省理工學院香港創新中心執行董事，在資訊技術和電信行業擁有25年經驗的資深人士。冼超舜在加拿大滑鐵盧大學獲得管理科學博士學位。目前，他是香港數碼港管理公司的董事會成員，香港理工大學商學院實踐教授及香港董事學會資深會員。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            名譽法律顧問
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            陳錦程律師
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/consultant/consultant-5.png"
                                                src="/img/consultant/consultant-5.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                                陳錦程律師事務所從1992年開業, 至今已有30年, 陳錦程律師對有關土木工程法律，建築工程及施工合同，訴訟、仲裁及調解，土地發展及重建及與商務合同及知識產權事，婚姻訴訟及遺產事務等相關之事宜具有多年豐富專業經驗。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/consultant/consultant-5.png"
                                                src="/img/consultant/consultant-5.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    陳錦程律師事務所從1992年開業, 至今已有30年, 陳錦程律師對有關土木工程法律，建築工程及施工合同，訴訟、仲裁及調解，土地發展及重建及與商務合同及知識產權事，婚姻訴訟及遺產事務等相關之事宜具有多年豐富專業經驗。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Box>
                </Grid>
            </Root>
        </RootBox>
    )

}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "contact-us";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.subtitle ? props.subtitle : "center"}
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}


const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.about`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 3,
        width: "100%"
        // marginBottom: 10
    },
    [`&.consultant`]: {
        background:
            theme.palette.mode === "dark"
                ? '#c9bea8'
                : '#c9bea8',
        borderBottomWidth: 5
    },

}));

export default ConsultantPage;
